import React, { Fragment } from 'react'

import { Trans } from '@lingui/macro'
import cn from '@meltdownjs/cn'

import { ArrowRightIcon } from '@heroicons/react/outline'

import { makeAvailability } from 'src/hooks/data/usePaginatedAvailabilities'

import useObserver from 'src/hooks/utils/useObserver'

import Currency from 'src/components/Currency'

import Availability from 'src/pages/Carts/CartGrid/components/Item/Availability'
import AvailabilityLabel from 'src/pages/Carts/CartGrid/components/Item/AvailabilityLabel'
import Quantity from 'src/pages/Carts/CartGrid/components/Item/Quantity'
import Total from 'src/pages/Carts/CartGrid/components/Item/Total'
import Images from 'src/pages/Carts/CartGrid/components/Item/images'

type ItemProps = {
  product: any
  currency: string
  pricesObserver: any
  updateCartItems: any
  availabilityObserver: any
  cartObserver: any
  openProductDetailsDialog: (product: any) => void
}

const Item: React.FC<ItemProps> = ({
  product,
  currency,
  pricesObserver,
  updateCartItems,
  availabilityObserver,
  cartObserver,
  openProductDetailsDialog,
}) => {
  const [quantityMessage, setQuantityMessage] = React.useState<string>('')
  const { quantity } = useObserver(
    cartObserver,
    (data) => ({
      quantity: data.items[`${product.id}.earliest-date`]?.quantity || 0,
      companyUserReference: data.companyUserReference,
    }),
    [`${product.id}.earliest-date`]
  )

  const [currentQuantity, setCurrentQuantity] = React.useState<number>(quantity)
  const isSaleable =
    product?.stockStatus &&
    (product.stockStatus !== 0 ||
      product.stockStatus !== undefined ||
      product.stockStatus !== null)
      ? true
      : false

  React.useEffect(() => {
    setCurrentQuantity(quantity)
  }, [quantity])

  const resolveCurrencyIsoCode = (currency: any, product: any) => {
    if (currency !== undefined) {
      return currency
    }

    return product.prices?.[0]?.currency?.code
  }

  const currencyIsoCode = resolveCurrencyIsoCode(currency, product)

  const availability = useObserver(
    availabilityObserver,
    (data: any) => {
      if (data === undefined) {
        return false
      }
      const periods = data[product.id] || []
      const availability = makeAvailability(periods)

      return availability
    },
    [product.id]
  )

  const price = useObserver(
    pricesObserver,
    (data: any) => {
      const price = data?.[product.id]
      if (!price) {
        return false
      }
      return price
    },
    [product.id]
  )

  return (
    <Fragment>
      <div className="rounded-md border bg-white">
        <div
          className="flex w-full cursor-pointer"
          onClick={openProductDetailsDialog}
        >
          <div className="p4 relative h-full w-full bg-gray-100 py-9">
            <div className="min-h-[12rem]">
              <Images images={product.images} />
            </div>
            <div className="absolute bottom-0 w-full">
              <AvailabilityLabel product={product} />
            </div>
          </div>
          <div
            className={cn(
              'relative mt-2.5 w-full border-b-0 px-3.5 md:border-b',
              {
                'border-b': isSaleable,
              }
            )}
          >
            <button
              type="button"
              className="float-right mb-4 flex items-center"
            >
              <span className="mr-1">
                <Trans>Details</Trans>
              </span>
              <span>
                <ArrowRightIcon className="h-4 w-4" />
              </span>
            </button>
            <div className="flex w-full text-xs">
              <span>{product.brand}</span>
            </div>
            <div className="max-h-48 w-full py-1 text-lg font-bold">
              <span>
                {product.model} {` • `} {product.style}{' '}
                {product.size && ` • ` + product.size}
              </span>
            </div>
            <div className="flex w-full flex-wrap pb-2 text-base">
              <span className="w-full flex-auto">{product.id}</span>
            </div>
            {((!!product.allowedQuantity.quantityInterval &&
              product.allowedQuantity.quantityInterval > 1) ||
              (!!product.allowedQuantity.quantityMax &&
                product.allowedQuantity.quantityMax !== Infinity)) && (
              <div className="absolute bottom-6 left-3.5">
                <div className="flex w-full flex-row justify-around space-x-3">
                  <div className="space-x-2">
                    <span>
                      <Trans>Interval</Trans>:
                    </span>
                    <span>{product.allowedQuantity.quantityInterval}</span>
                  </div>
                  <div className="space-x-2">
                    <span>
                      <Trans>Max</Trans>:
                    </span>
                    <span>{product.allowedQuantity.quantityMax}</span>
                  </div>
                </div>
              </div>
            )}
            <div className="absolute bottom-1 right-3.5">
              <div className="flex w-full justify-end text-base font-semibold">
                <div className="mr-1">
                  <Trans>Price</Trans>:
                </div>
                <div>
                  <Currency
                    cents={!!price ? price : 0}
                    currencyIsoCode={
                      !!currencyIsoCode ? currencyIsoCode : 'EUR'
                    }
                  />{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={cn(
            'float-left hidden w-full px-3.5 py-5 md:block md:min-h-[8rem]',
            {
              block: isSaleable,
            }
          )}
        >
          {isSaleable && (
            <Fragment>
              <div className="mb-5">
                <div className="flex w-full items-center">
                  <Availability
                    product={product}
                    availabilityObserver={availabilityObserver}
                  />
                  <div className="ml-auto flex">
                    <Quantity
                      availability={availability}
                      id={`${product.id}.earliest-date`}
                      quantityMessage={quantityMessage}
                      setQuantityMessage={setQuantityMessage}
                      setQuantity={setCurrentQuantity}
                      quantity={currentQuantity}
                      updateCartItems={updateCartItems}
                    />
                  </div>
                </div>
                {quantityMessage && (
                  <div className="mb-5 w-full px-5 text-yellow-600">
                    <span>{quantityMessage}</span>
                  </div>
                )}
              </div>
              <div className="float-right">
                <Total
                  price={price}
                  currencyIsoCode={currencyIsoCode}
                  quantity={quantity}
                />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  )
}
export default Item
