import React from 'react'

import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useFloating,
  useTransitionStatus,
  useTransitionStyles,
} from '@floating-ui/react'
import { Trans, t } from '@lingui/macro'
import cn from '@meltdownjs/cn'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import {
  ColorSwatchIcon,
  CubeIcon,
  ScaleIcon,
  XIcon,
} from '@heroicons/react/outline'

import {
  AVAILABILITY_STATES,
  makeAvailability,
} from 'src/hooks/data/usePaginatedAvailabilities'

import useObserver from 'src/hooks/utils/useObserver'

import Currency from 'src/components/Currency'

import Quantity from 'src/pages/Carts/CartGrid/components/Item/Quantity'
import Total from 'src/pages/Carts/CartGrid/components/Item/Total'
import { Product } from 'src/pages/Carts/CartGrid/components/Item/types'

import { ReactComponent as MeasureArrowIcon } from 'src/assets/images/measure-arrow.svg'

type DetailsDialogProps = {
  availabilityObserver: any
  cartObserver: any
  closeProductGridViewDetailsDialog: () => void
  currency: string
  product: Product
  pricesObserver: any
  updateCartItems: () => void
}

const DetailsDialog: React.FC<DetailsDialogProps> = ({
  availabilityObserver,
  cartObserver,
  closeProductGridViewDetailsDialog,
  currency,
  product,
  pricesObserver,
  updateCartItems,
}) => {
  const hasImages = product.images && product.images.length > 0
  const stockStatus = product?.stockStatus
  const [isOpen, setIsOpen] = React.useState(false)
  const [quantityMessage, setQuantityMessage] = React.useState<string>('')

  const { quantity } = useObserver(
    cartObserver,
    (data) => ({
      quantity: data.items[`${product.id}.earliest-date`]?.quantity || 0,
      companyUserReference: data.companyUserReference,
    }),
    [`${product.id}.earliest-date`]
  )

  const [currentQuantity, setCurrentQuantity] = React.useState<number>(quantity)

  React.useEffect(() => {
    setCurrentQuantity(quantity)
  }, [quantity])

  const resolveCurrencyIsoCode = (currency: any, product: any) => {
    if (currency !== undefined) {
      return currency
    }
    return product.prices[0]?.currency.code
  }

  const currencyIsoCode = resolveCurrencyIsoCode(currency, product)
  const availability = useObserver(
    availabilityObserver,
    (data: any) => {
      if (data === undefined) {
        return false
      }
      const periods = data[product.id] || []
      const availability = makeAvailability(periods)

      return availability
    },
    [product.id]
  )

  const price = useObserver(
    pricesObserver,
    (data: any) => {
      const price = data?.[product.id]
      if (!price) {
        return false
      }
      return price
    },
    [product.id]
  )

  const onClose = () => {
    updateCartItems({ [`${product.id}.earliest-date`]: currentQuantity })
    closeProductGridViewDetailsDialog()
  }

  const colors = Array.isArray(product.colors)
    ? product.colors
    : product.colors?.split?.(',') ?? []

  const { context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  })

  const { styles } = useTransitionStyles(context, {
    duration: 2000,
    initial: {
      opacity: 1,
      transform: 'transition-duration: 250ms',
    },
  })

  const { status } = useTransitionStatus(context, {
    duration: 2000,
  })

  const onChangeQuantity = async (value) => {
    setQuantity(value)
  }

  return (
    <FloatingPortal>
      <FloatingOverlay
        className="fixed inset-0 z-20 flex items-center justify-center overflow-auto bg-gray-900 bg-opacity-25"
        lockScroll
      >
        <FloatingFocusManager context={context}>
          <div
            style={{ ...styles }}
            data-status={status}
            className="fixed right-0 top-14 z-10 flex h-full w-full flex-col overflow-y-auto bg-white pb-4 shadow-2xl md:w-1/2 lg:w-1/2 xl:top-10 xl:w-2/5 2xl:w-1/4"
          >
            <button
              className="absolute right-3.5 top-0 z-10 m-2 flex h-10 items-center justify-center md:h-8"
              onClick={onClose}
            >
              <Trans>Close</Trans>
              <XIcon className="h-6 w-6" />
            </button>
            <Swiper
              direction={'vertical'}
              slidesPerView={'auto'}
              className="h-fit top-0 w-full flex-shrink-0"
            >
              <SwiperSlide>
                {hasImages && (
                  <div className="flex h-full w-full bg-gray-10">
                    <Swiper
                      cssMode={true}
                      navigation={true}
                      pagination={true}
                      mousewheel={true}
                      keyboard={true}
                      modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                      className="galery swiper-product-image"
                    >
                      {product.images!.map((image, i) => (
                        <SwiperSlide key={i} className="">
                          <img
                            src={image.medium}
                            key={i}
                            alt={product?.style + '-' + i}
                            className="mx-auto my-9 w-96 rounded-lg"
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
              </SwiperSlide>
            </Swiper>
            <div className="p-5">
              <div className="text-sm font-light">{product.brand}</div>
              <div className="mb-2.5 text-xl font-bold">
                <span>
                  {product.model} {` • `} {product.style}{' '}
                  {product.size && ` • ` + product.size}
                </span>
              </div>
              <div className="flex">
                <div className="text-base font-extralight">
                  {product.sku}
                  {!product.sku &&
                    product.abstractSku?.replace('Abstract-', '')}
                </div>
                <div className="ml-auto flex justify-end text-base font-semibold">
                  <div className="mr-1">
                    <Trans>Price</Trans>:
                  </div>
                  <div>
                    <Currency
                      cents={!!price ? price : 0}
                      currencyIsoCode={
                        !!currencyIsoCode ? currencyIsoCode : 'EUR'
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-5 px-5">
              <hr />
            </div>
            {availability.state !== AVAILABILITY_STATES.unavailable ? (
              <div>
                <div className="mb-2.5 px-5">
                  <span className="text-base font-semibold">
                    <Trans>Add article:</Trans>
                  </span>
                </div>
                <div className="grid grid-cols-3 px-5">
                  <div
                    className={cn('mr-5 flex items-center rounded-md', {
                      'bg-red-200, px-2.5':
                        stockStatus === 0 ||
                        stockStatus === undefined ||
                        stockStatus === null,
                      'bg-yellow-200 px-2.5': stockStatus === 1,
                    })}
                  >
                    <span
                      className={cn('float-left font-semibold', {
                        'text-red-600':
                          stockStatus === 0 ||
                          stockStatus === undefined ||
                          stockStatus === null,
                        'text-yellow-600': stockStatus === 1,
                      })}
                    >
                      {t`Available from ${i18n.date(
                        availability.earliestDeliveryDate,
                        {
                          day: '2-digit',
                          month: '2-digit',
                          year: '2-digit',
                        }
                      )}`}
                    </span>
                  </div>
                  <div>
                    <Quantity
                      availability={availability}
                      id={`${product.id}.earliest-date`}
                      onChange={onChangeQuantity}
                      quantityMessage={quantityMessage}
                      quantity={currentQuantity}
                      setQuantityMessage={setQuantityMessage}
                      setQuantity={setCurrentQuantity}
                      updateCartItems={updateCartItems}
                    />
                  </div>
                  <div className="flex w-full items-center justify-end text-base">
                    <Total
                      price={price}
                      currencyIsoCode={currencyIsoCode}
                      quantity={quantity}
                    />
                  </div>
                </div>
                {quantityMessage && (
                  <div className="mt-2.5 w-full px-5 text-yellow-600">
                    <span>{quantityMessage}</span>
                  </div>
                )}
              </div>
            ) : (
              <div className="px-5">
                <span className="block flex h-9 items-center justify-center bg-red-200 py-0.5 text-center font-semibold text-red-600">
                  <Trans>Sold Out</Trans>
                </span>
              </div>
            )}
            <div className="p-5">
              <hr />
            </div>
            <div className="px-5">
              <div className="text-1xl font-small float-left font-bold">
                <Trans>Product Details</Trans>
              </div>
            </div>
            <div
              className="grid gap-2 px-5 font-light"
              style={{
                gridTemplateColumns: 'minmax(0, auto) minmax(0, auto)',
              }}
            >
              <div className="flex items-center space-x-2">
                <span className="w-4 " />
                <div>
                  <Trans>GTIN</Trans>
                </div>
              </div>

              <div>{product.gtin}</div>

              <div className="flex items-center space-x-2">
                <ColorSwatchIcon className="w-4 text-gray-700" />
                <div>
                  <Trans>Colors</Trans>
                </div>
              </div>

              <div>{colors.join(' • ') || '-'}</div>

              <div className="flex items-center space-x-2">
                <MeasureArrowIcon className="w-4 text-gray-700" />
                <div>
                  <Trans>Dimensions</Trans>
                </div>
              </div>

              <div>
                {product.width && product.height && product.depth ? (
                  <Trans>
                    {product.width} x {product.height} x {product.depth} cm
                  </Trans>
                ) : (
                  '-'
                )}
              </div>

              <div className="flex items-center space-x-2">
                <CubeIcon className="w-4 text-gray-700" />
                <div>
                  <Trans>Volume</Trans>
                </div>
              </div>

              <div>
                {product.volume ? (
                  <Trans>
                    <span>{product.volume} L</span>
                  </Trans>
                ) : (
                  '-'
                )}
              </div>

              <div className="flex items-center space-x-2">
                <ScaleIcon className="w-4 text-gray-700" />
                <div>
                  <Trans>Weight</Trans>
                </div>
              </div>

              <div>
                {product.weight ? <Trans>{product.weight} g</Trans> : '-'}
              </div>
            </div>
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  )
}

export default DetailsDialog
