import React, { Fragment } from 'react'

import { Trans } from '@lingui/macro'
import cn from '@meltdownjs/cn'
import { format, isDate } from 'date-fns'
import { useDropzone as useReactDropzone } from 'react-dropzone'
import * as XLSX from 'xlsx'

import {
  ExclamationCircleIcon,
  RefreshIcon,
  TrashIcon,
  UploadIcon,
} from '@heroicons/react/outline'
import { CheckIcon } from '@heroicons/react/solid'

import Button from 'src/components/Button'

const DELIVERY_DATE_COLUMN_INDEX_IDENTIFIER =
  /(Menge|Quantity) (LT|DD)\s?[0-9]{1,2}/
const DELIVERY_DATE_COLUMN_DATE_EXTRACTOR =
  /([0-9]{2,2}.[0-9]{2,2}.[0-9]{4,4}|earliest-date)/
const PRODUCT_ROW_IDENTIFIER =
  /[A-Za-z0-9]+-[A-Za-z0-9]+-[A-Za-z0-9]+(-[A-Za-z0-9]+)?/

type DeliveryDateColumn = {
  cell: string
  index: number
  date: Date
  parsedDate: string
}

const getDeliveryDateColumns = (
  jsonFromExcel: unknown[][]
): DeliveryDateColumn[] => {
  const matches = []

  for (const row in jsonFromExcel) {
    for (const col in jsonFromExcel[row]) {
      const cell = jsonFromExcel[row][col]
      const match =
        typeof cell === 'string' &&
        cell.match(DELIVERY_DATE_COLUMN_INDEX_IDENTIFIER)

      if (!match) {
        continue
      }

      const [dateMatch] = cell.match(DELIVERY_DATE_COLUMN_DATE_EXTRACTOR) || []
      if (!!dateMatch) {
        const [day, month, year] = dateMatch?.split('.')
        const deliveryDate = new Date(
          parseInt(year, 10),
          parseInt(month, 10) - 1,
          parseInt(day, 10)
        )

        if (dateMatch === 'earliest-date') {
          matches.push({
            cell,
            index: parseInt(col, 10),
            date: deliveryDate,
            parsedDate: dateMatch,
          })
        } else if (isDate(deliveryDate)) {
          matches.push({
            cell,
            index: parseInt(col, 10),
            date: deliveryDate,
            parsedDate: format(deliveryDate, 'yyyy-MM-dd'),
          })
        }
      }
    }
  }

  return matches
}

const getProductRows = (jsonFromExcel: unknown[][]): unknown[][] => {
  const products = []

  for (const row of jsonFromExcel) {
    if (typeof row[0] === 'string' && row[0].match(PRODUCT_ROW_IDENTIFIER)) {
      products.push(row)
    }
  }

  return products
}

const parseExcelToLineItems = (excelSheet: XLSX.WorkSheet) => {
  const jsonFromExcel = XLSX.utils.sheet_to_json(excelSheet, {
    raw: false,
    dateNF: 'MM-DD-YYYY',
    header: 1,
    defval: '',
  }) as unknown[][]

  const deliveryDates = getDeliveryDateColumns(jsonFromExcel)
  const products = getProductRows(jsonFromExcel)
  const lineItems = generateLineItems(deliveryDates, products)

  return lineItems
}

const generateLineItems = (
  deliveryDates: DeliveryDateColumn[],
  products: unknown[][]
): MinimalLineItems => {
  const cartItems: MinimalLineItems = {}
  for (const product of products) {
    for (const deliveryDate of deliveryDates) {
      const quantityAsString = product[deliveryDate.index]

      if (quantityAsString === '' || typeof quantityAsString !== 'string') {
        continue
      }

      const quantity = parseInt(quantityAsString, 10)

      if (deliveryDate.parsedDate && quantityAsString) {
        cartItems[`${product[0]}.${deliveryDate.parsedDate}`] = {
          quantity: isNaN(quantity) ? -1 : quantity,
        }
      }
    }
  }
  return cartItems
}

const useDropzone = (
  onDropAccepted?: (minimalCart: MinimalCart) => void,
  onDeleteFile?: (name: string) => void
) => {
  const [isIdle, setIsIdle] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isSuccess, setIsSuccess] = React.useState(false)
  const [isError, setIsError] = React.useState(false)

  const defaultOnDropAccepted = ([file]: File[]) => {
    setIsIdle(false)
    setIsLoading(true)
    const reader = new FileReader()
    const readAsBinaryString = !!reader.readAsBinaryString
    reader.onabort = () => setIsError(true)
    reader.onerror = () => setIsError(true)
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const bstr = e.target?.result
      const workbook = XLSX.read(bstr, {
        type: readAsBinaryString ? 'binary' : 'array',
      })
      const [sheet_name_list] = workbook.SheetNames
      const excelSheet = workbook.Sheets[sheet_name_list]
      onDropAccepted?.({
        name: file.name,
        lineItems: parseExcelToLineItems(excelSheet) as MinimalLineItems,
      })
      setIsLoading(false)
      setIsSuccess(true)
    }
    if (readAsBinaryString) {
      reader.readAsBinaryString(file)
      return
    }

    reader.readAsArrayBuffer(file)
  }

  const { acceptedFiles: files, ...dropzoneProps } = useReactDropzone({
    maxFiles: 1,
    accept: { 'application/*': ['.xlsx', '.xls'] },
    onDropAccepted: defaultOnDropAccepted,
  })

  const acceptedFiles = files

  const deleteFile = (_name: string) => {
    acceptedFiles.splice(
      acceptedFiles.findIndex(({ name }) => name === _name),
      1
    )
    onDeleteFile?.(_name)
    setIsIdle(true)
    setIsLoading(false)
    setIsSuccess(false)
    setIsError(false)
  }

  return {
    ...dropzoneProps,
    isLoading,
    isSuccess,
    isError,
    isIdle,
    deleteFile,
    acceptedFiles,
  }
}

type MinimalLineItems = {
  [k: string]: {
    quantity: number
  }
}

export type MinimalCart = {
  id?: string
  name: string
  lineItems: MinimalLineItems
}

type DropzoneProps = {
  error?: string
  onDropAccepted?: (minimalCart: MinimalCart) => void
  onDeleteFile?: (name: string) => void
}

const Dropzone: React.FC<DropzoneProps> = ({
  error,
  onDropAccepted,
  onDeleteFile,
}) => {
  const {
    isDragActive,
    isFocused,
    isLoading,
    isIdle,
    isSuccess,
    getRootProps,
    getInputProps,
    open,
    acceptedFiles: [acceptedFile] = [],
    deleteFile,
  } = useDropzone(onDropAccepted, onDeleteFile)

  return (
    <div className="flex flex-col items-center space-y-2">
      <div className="flex w-full flex-col">
        <div
          {...getRootProps({
            className: cn(
              'flex flex-col w-full h-36 bg-gray-10 items-center justify-center border-2 border-transparent rounded',
              (isFocused || isDragActive) && 'border-aubergine border-dashed'
            ),
          })}
        >
          <input {...getInputProps()} />
          {isLoading && (
            <Fragment>
              <RefreshIcon className="h-10 w-10" />
              <span className="mt-4">
                <Trans>Uploading ...</Trans>
              </span>
            </Fragment>
          )}
          {isIdle && (
            <Fragment>
              <UploadIcon className="h-10 w-10" />
              <span className="mt-4">
                <Trans>Drag 'n' drop your file</Trans>
              </span>
            </Fragment>
          )}
          {isSuccess && !error && (
            <Fragment>
              <CheckIcon className="h-10 w-10 text-green-500" />
              <span className="mt-4 text-green-500">
                <Trans>Upload success</Trans>
              </span>
            </Fragment>
          )}
          {isSuccess && !!error && (
            <Fragment>
              <ExclamationCircleIcon className="h-10 w-10 text-red-500" />
              <span className="mt-4 text-red-500">
                <Trans>Upload failed</Trans>
              </span>
            </Fragment>
          )}
        </div>
        {!!error && (
          <div className="text-sm font-light text-red-500">{`${error}`}</div>
        )}
      </div>
      <div className="flex h-36 flex-col items-center justify-around p-4">
        {!acceptedFile && (
          <Fragment>
            <Trans>or</Trans>
            <div className="flex flex-col items-center space-y-2">
              <Button type="button" size="md" variant="gray" onClick={open}>
                <Trans>Select file</Trans>
              </Button>
              <span className="text-xs text-gray-300">
                <Trans>Accepted file format: XLSX</Trans>
              </span>
            </div>
          </Fragment>
        )}

        {isSuccess && acceptedFile && (
          <Button
            onClick={() => deleteFile(acceptedFile.name)}
            className="text-md flex items-center space-x-2 break-all rounded p-2"
            variant="none"
          >
            <TrashIcon className="w-6 flex-shrink-0" />
            <span>{acceptedFile.name}</span>
          </Button>
        )}
      </div>
    </div>
  )
}

export default Dropzone
