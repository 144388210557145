import React from 'react'

import { defineMessage, t } from '@lingui/macro'
import cn from '@meltdownjs/cn'

import { MinusIcon, PlusIcon } from '@heroicons/react/outline'

defineMessage({
  id: 'message_max_quantity_number',
  message: 'The product is unfortunately not available in this quantity.',
})

type QuantityProps = {
  availability: any
  id: string
  setQuantity: () => void
  setQuantityMessage: () => void
  quantity: number
  quantityMessage: string
  updateCartItems: () => void
}

const Quantity: React.FC<QuantityProps> = ({
  availability,
  id,
  setQuantity,
  setQuantityMessage,
  quantity,
  quantityMessage,
  updateCartItems,
}) => {
  const inputRef = React.useRef()

  let inputState = ''

  if (quantity > 0) {
    inputState =
      quantityMessage === null || quantityMessage.length === 0
        ? 'valid'
        : quantityMessage && quantityMessage.length > 0 && 'invalid'
  }

  const increaseValue = () => {
    const newValue = (parseInt(quantity, 10) || 0) + 1

    if (newValue === availability.quantity) {
      setQuantityMessage(t({ id: `message_max_quantity_number` }))
      return
    }

    onChange(newValue)
  }

  const decreaseValue = () => {
    const newValue = quantity > 1 ? quantity - 1 : 0

    if (newValue < availability.quantity && quantityMessage) {
      setQuantityMessage('')
      return
    }

    if (!Number.isInteger(newValue)) {
      setTimeout(() => {
        inputRef?.current?.focus?.()
      })
    }

    onChange(newValue)
  }

  const onChange = (value: number) => {
    setQuantity(value)
    updateCartItems({ [id]: value })
  }

  const onInputChange = (event) => {
    const newValue = parseInt(event.target.value, 10)

    if (newValue >= availability.quantity) {
      setQuantityMessage(t({ id: `message_max_quantity_number` }))
    }

    if (newValue < availability.quantity && quantityMessage) {
      setQuantityMessage('')
      return
    }

    onChange({ newValue })

    if (!Number.isNaN(newValue)) {
      onChange(newValue)
      return
    }

    onChange(0)
  }

  return (
    <div className="flex rounded">
      <button
        className="button-color-gray flex items-center rounded-l-md border p-2.5"
        onClick={decreaseValue}
      >
        <MinusIcon className="h-6 w-6" />
      </button>
      <div>
        <input
          ref={inputRef}
          data-ignore-focus-style
          onChange={onInputChange}
          className={cn(
            'h-12 w-14 appearance-none border p-2.5 text-center placeholder-gray-200',
            {
              'bg-gray-100 text-gray-800': inputState === 'pending',
              'bg-red-50 text-red-800': inputState === 'invalid',
              'bg-green-50 text-green-800': inputState === 'valid',
            }
          )}
          placeholder="0"
          value={quantity}
        />
      </div>
      <button
        className="button-color-gray flex items-center rounded-r-md border p-2.5"
        onClick={increaseValue}
      >
        <PlusIcon className="h-6 w-6" />
      </button>
    </div>
  )
}

export default Quantity
