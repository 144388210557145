import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { Trans } from '@lingui/macro'
import cn from '@meltdownjs/cn'
import colors from 'tailwindcss/colors'

import {
  ArrowSmRightIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
} from '@heroicons/react/outline'

import { CompanyUser } from 'src/api/company-user-search/types'

// import { CompanyUser } from 'src/api/company-user-search/types'
import useCart from 'src/hooks/data/useCart'
import useDefaultCompanyUser from 'src/hooks/data/useDefaultCompanyUser'
import useOrderBudget from 'src/hooks/data/useOrderBudget'

import useDefaultCompanyUserPermissions from 'src/hooks/utils/useDefaultCompanyUserPermissions'

// import useDefaultCompanyUser from 'src/hooks/data/useDefaultCompanyUser'
// import useOrderBudget from 'src/hooks/data/useOrderBudget'
// import useDefaultCompanyUserPermissions from 'src/hooks/utils/useDefaultCompanyUserPermissions'
import Currency from 'src/components/Currency'
// import Tooltip from 'src/pages/Carts/CartGrid/components/CheckoutButton/Tooltip'
import {
  FloatingArrow,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from 'src/components/Tooltip'

// type CartFooterProps = {
//   companyUserReference?: string
// }

// const CartFooter: React.FC<CartFooterProps> = React.memo(
//   ({ companyUserReference }) => {
//     const { cartId } = useParams()
//     const { status: cartItemsUpdateStatus } = useUpdateCartItems({
//       companyUserId: companyUserReference,
//       cartId,
//     })
//     const cartQuery = useCart(cartId, {
//       notifyOnChangeProps: 'tracked',
//       select: ({
//         items,
//         totals,
//         currency,
//         validationMessages,
//         ...rest
//       }: {
//         items: Record<string, any>
//         totals: any
//         currency: any
//         validationMessages: any[]
//       }) => {
//         console.log(rest)
//         return {
//           totals,
//           currency,
//           itemsWithMessages: Object.values(items).filter(
//             ({ messages }: { messages: string[] }) => messages?.length > 0
//           ),
//           totalItems: Object.values(items).reduce(
//             (sum: number, { quantity }: { quantity: number }) => sum + quantity,
//             0
//           ),
//           hasMessages: validationMessages.length > 0,
//           hasItemsWithMessages: Object.values(items).some(
//             ({ messages }: { messages: string[] }) => messages?.length > 0
//           ),
//         }
//       },
//     })

//     const cartState = React.useMemo(() => {
//       if (cartItemsUpdateStatus === 'loading') {
//         return 'loading'
//       }
//       if (cartQuery.data?.totalItems === 0) {
//         return 'empty'
//       }
//       if (cartQuery.data?.hasMessages || cartQuery.data?.hasItemsWithMessages) {
//         return 'invalid'
//       }
//       return 'valid'
//     }, [
//       cartItemsUpdateStatus,
//       cartQuery.data?.totalItems,
//       cartQuery.data?.hasMessages,
//       cartQuery.data?.hasItemsWithMessages,
//     ])

//     const orderBudgetQuery = useOrderBudget(companyUserReference!, {
//       enabled: !!companyUserReference,
//     })

//     const currencyIsoCode = cartQuery.data?.currency

//     if (cartState === 'empty') {
//       return null
//     }

//     return (
//       <div className="group relative flex h-full items-center space-x-4">
//         <div className="h-10 w-10">
//           {cartItemsUpdateStatus === 'loading' ? (
//             <span className="text-gray-400">
//               <Spinner size="100%" color="currentColor" />
//             </span>
//           ) : (
//             <Fragment>
//               {cartState === 'valid' && (
//                 <span className="text-green-500">
//                   <CheckCircleIcon className="h-full w-full" />
//                 </span>
//               )}

//               {cartState === 'invalid' && (
//                 <span className="text-red-600">
//                   <ExclamationCircleIcon className="h-full w-full" />
//                 </span>
//               )}
//             </Fragment>
//           )}
//         </div>

//         {cartState === 'valid' && (
//           <Link
//             to={`checkout`}
//             className="flex h-10 items-center space-x-2 rounded px-4 text-white bg-gradient-purple hover:bg-gradient-purple-light active:bg-gradient-purple-dark"
//           >
//             <div>
//               <span>
//                 <Trans>To checkout</Trans>
//               </span>
//               <ArrowSmRightIcon className="w-6" />
//             </div>
//             <Currency
//               cents={orderBudgetQuery.data?.budget || 0}
//               currencyIsoCode={currencyIsoCode}
//               className="text-xs font-semibold"
//             />
//           </Link>
//         )}

//         {cartState === 'loading' && (
//           <div className="flex h-10 cursor-wait items-center space-x-2 rounded border border-gray-500 px-4 text-gray-700">
//             <span>
//               <Trans>To checkout</Trans>
//             </span>
//             <ArrowSmRightIcon className="w-6" />
//           </div>
//         )}
//       </div>
//     )
//   }
// )

const CheckoutButton = () => {
  const { cartId, companyId } = useParams()
  const { data: cart } = useCart(cartId, { enabled: !!cartId })
  const { data: companyUser } = useDefaultCompanyUser<CompanyUser>()
  const { ability } = useDefaultCompanyUserPermissions()
  const { data: orderBudget, isSuccess } = useOrderBudget(
    companyUser?.companyUserReference!,
    {
      enabled: !!companyUser?.companyUserReference,
    }
  )

  const hasMultipleDeliveryDates = cart?.deliveryDates.length > 1

  const hasOrderBudget =
    isSuccess &&
    !!orderBudget &&
    cart?.companyUserReference === companyUser?.companyUserReference &&
    ability.can('see', 'orderBudget')

  const availableBudget = hasOrderBudget
    ? orderBudget?.budget - cart.totals.subtotal
    : 0

  const hasNegativeBudget = availableBudget < 0

  return (
    <Link
      to={`/${companyId}/carts/${cartId}/checkout`}
      replace
      state={{ from: 'grid' }}
    >
      <div
        className={cn(
          'flex h-16 rounded-md bg-green-400 text-gray-900 shadow-lg drop-shadow-lg',
          {
            'bg-red-600 text-gray-50': hasNegativeBudget,
          }
        )}
      >
        {hasNegativeBudget && (
          <Tooltip open={hasNegativeBudget}>
            <TooltipTrigger>
              <div className="flex h-full items-center justify-center rounded-l-md bg-red-200 p-3 text-red-700">
                <span className="h-8 w-8">
                  <ExclamationCircleIcon />
                </span>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <div className="py- rounded-md bg-red-600 px-4 text-gray-50 shadow-lg drop-shadow-lg">
                <Trans>
                  Weekly budget exceeded by{' '}
                  <Currency cents={availableBudget} currencyIsoCode={'EUR'} />
                </Trans>
              </div>
              <FloatingArrow fill={colors.red[600]} />
            </TooltipContent>
          </Tooltip>
        )}

        {hasMultipleDeliveryDates && !hasNegativeBudget && (
          <Tooltip initialOpen={true}>
            <TooltipTrigger>
              <div className="flex h-full items-center justify-center rounded-l-md bg-green-100 p-3 text-green-800">
                <span className="h-8 w-8">
                  <ExclamationIcon />
                </span>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <div className="flex space-x-2 rounded-md bg-green-100 px-4 py-3 text-gray-900 shadow-lg drop-shadow-lg">
                <div>
                  <ExclamationIcon className="h-8 w-8 text-green-800" />
                </div>
                <div className="max-w-[220px]">
                  <Trans>
                    This shopping cart has several delivery dates. You can edit
                    these in the checkout.
                  </Trans>
                </div>
              </div>
              <FloatingArrow fill={colors.green[100]} />
            </TooltipContent>
          </Tooltip>
        )}

        <div
          className={cn('flex flex-col items-center justify-center px-6 py-4', {
            'space-x-3': hasOrderBudget,
          })}
        >
          <div className="flex h-10 items-center space-x-2">
            <span className="text-base font-semibold">
              <Trans>To checkout</Trans>
            </span>
            <span className="w-6">
              <ArrowSmRightIcon />
            </span>
          </div>
          {hasOrderBudget && (
            <Currency
              cents={availableBudget}
              currencyIsoCode={'EUR'}
              className="text-xs font-semibold"
            />
          )}
        </div>
      </div>
    </Link>
  )
}

export default CheckoutButton
